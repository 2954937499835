import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css';
import DarkModeButton from '../DarkModeButton/DarkModeButton';
import logoBlack from './img/logo-black.png';
import logoWhite from './img/logo-white.png';
import MenuButton from '../MenuButton/MenuButton';
import { BiMenu, BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useDarkMode } from '../../context/DarkModeContext';

export default function Navbar({ menus }) {
  const { darkMode } = useDarkMode();
  const logo = darkMode ? logoWhite : logoBlack;
  const [openMenuBar, setOpenMenuBar] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [selectedMenuTitle, setSelectedMenuTitle] = useState();
  const toggleOpenMenuBar = () => {
    setShowBackground(!openMenuBar);
    setOpenMenuBar((prev) => !prev);
  };
  const handleSelectMenu = (title) => {
    window.scrollTo(0, 0);

    setShowBackground(false);
    setOpenMenuBar(false);
    setSelectedMenuTitle(title);
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const minScrollYToShowBackground = 10;

      setShowBackground(window.scrollY > minScrollYToShowBackground);
      setOpenMenuBar(false);
    });

    window.addEventListener('resize', () => setOpenMenuBar(false));
  }, []);

  return (
    <header
      className={`${styles['main-container']} ${
        showBackground && styles.accent
      }`}
    >
      <div className={styles['left-side']}>
        <div className={styles['title-container']}>
          <img src={logo} alt="logo" />
          <Link
            to="/"
            className={styles.title}
            onClick={() => handleSelectMenu('/')}
          >
            베를린 새누리교회
          </Link>
          <DarkModeButton />
        </div>

        <button
          className={styles['toggle-button-wrapper']}
          onClick={toggleOpenMenuBar}
        >
          <BiMenu
            className={`${styles['toggle-button']} ${
              !openMenuBar && styles.show
            }`}
          />
          <BiX
            className={`${styles['toggle-button']} ${
              openMenuBar && styles.show
            }`}
          />
        </button>
      </div>

      <nav>
        <ul className={`${styles.menus} ${openMenuBar && styles.open}`}>
          {menus.map((menu, index) => (
            <MenuButton
              key={index}
              menu={menu}
              selectedMenuTitle={selectedMenuTitle}
              onClick={() => handleSelectMenu(menu.title)}
              openMenuBar={openMenuBar}
            />
          ))}
        </ul>
      </nav>
    </header>
  );
}
