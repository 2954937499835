import React from 'react';
import styles from './Footer.module.css';
import { BsYoutube } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { MdFacebook } from 'react-icons/md';

export default function Footer() {
  return (
    <footer className={styles.container}>
      <section className={styles['title-container']}>
        <h1>{churchName}</h1>
        <a className={styles.maker} href="https://tokenkim.de/" target="blank">
          site by TokenKim
        </a>
      </section>

      <section className={styles['info-container']}>
        <article className={styles.address}>
          <h2>address.</h2>
          <p>Borsigstraße 5, 10115 Berlin, Germany</p>
        </article>

        <article className={styles.info}>
          <h2>info.</h2>
          <p className={styles['info-contents']}>Tel.: +49-1767-2169883</p>
          <a
            className={styles['info-contents']}
            href="mailto: hanjoorang@daum.net"
          >
            hanjoorang@daum.net
          </a>
        </article>

        <article className={styles.channels}>
          <h2>channels.</h2>
          <a
            className={styles.icon}
            href="https://www.youtube.com/@berlinsaenuree"
            target="blank"
          >
            <BsYoutube />
          </a>
          <a
            className={styles.icon}
            href="https://www.instagram.com/saenuree_berlin/"
            target="blank"
          >
            <AiFillInstagram />
          </a>
          <a
            className={styles.icon}
            href="https://www.facebook.com/profile.php?id=100066258201031"
            target="blank"
          >
            <MdFacebook />
          </a>
        </article>
      </section>
    </footer>
  );
}

const churchName = '베를린 새누리교회';
