import React from 'react';
import styles from './Location.module.css';
import Map from '../../components/Map/Map';
import { HiOutlineMap } from 'react-icons/hi';
import {
  MdOutlineTram,
  MdOutlineDirectionsBusFilled,
  MdOutlineSubway,
  MdOutlineTrain,
} from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import IconCard from '../../components/IconCard/IconCard';
import Background from '../../components/Background/Background';
import backgroundImg from './img/background.jpg';
import churchImg01 from './img/church1.png';
import churchImg02 from './img/church2.png';
import churchImg03 from './img/church3.png';
import churchImg04 from './img/church4.png';
import ImageSlider from '../../components/ImageSlider/ImageSlider';

export default function Location() {
  return (
    <section className={styles.container}>
      <Background
        title={'위치 안내'}
        subtitle={'We are here'}
        imageUrl={backgroundImg}
      />
      <ul className={styles['icon-cards']}>
        {infos.map(({ icon, title, infos, links }, index) => (
          <IconCard key={index} title={title} infos={infos} links={links}>
            {icon}
          </IconCard>
        ))}
      </ul>

      <article className={styles.map}>
        <p className={styles.subtitle}> Theologisches Konvikt</p>
        <div className={styles.divider}></div>
        <Map />
      </article>

      <button className={styles.button}>
        <a
          className={styles.link}
          href={
            'https://www.google.com/maps/place/BERLIN+SAENUREE+CHURCH/@52.5288882,13.3858649,17z/data=!3m2!4b1!5s0x47a851eed117464d:0xa5ea2979b5a19360!4m6!3m5!1s0x47a84e20a6f55faf:0xa988a0988c544bf3!8m2!3d52.5288883!4d13.3907412!16s%2Fg%2F11c2mcrqs9'
          }
          target="blank"
        >
          Google 지도로 찾기
        </a>
      </button>

      <article className={styles.map}>
        <p className={styles.subtitle}>GROSSER SAAL</p>
        <div className={styles.divider}></div>
        <ImageSlider imgUrlList={imgUrlList} />
      </article>
    </section>
  );
}

const infos = [
  {
    icon: <HiOutlineMap />,
    title: 'address',
    infos: [' Borsigstraße 5', '10115', 'Berlin'],
  },
  {
    icon: <MdOutlineSubway />,
    title: 'U - Bahn',
    infos: ['U6번', 'OranienburgerTor', '도보 7분'],
  },
  {
    icon: <MdOutlineTrain />,
    title: 'S - Bahn',
    infos: ['S1번, S2번', 'Nordbahnhof ', '도보 4분'],
  },
  {
    icon: <MdOutlineTram />,
    title: 'Tram',
    infos: ['M5번', 'OranienburgerTor', '도보 6분'],
  },
  {
    icon: <MdOutlineDirectionsBusFilled />,
    title: 'Bus',
    infos: ['142번', 'Tucholskystr.', '도보 2분'],
  },
  {
    icon: <AiOutlineInfoCircle />,
    title: '대중교통',
    infos: ['BVG'],
    links: ['https://www.bvg.de/de'],
  },
];
const imgUrlList = [churchImg01, churchImg02, churchImg03, churchImg04];
