import React, { useEffect, useState } from 'react';
import styles from './Greetings.module.css';
import Background from '../../../components/Background/Background';
import Timeline from '../../../components/Timeline/Timeline';
import backgroundImg from './img/background.jpg';
import churchImg01 from './img/20130512.png';
import churchImg02 from './img/20131025.png';
import churchImg03 from './img/20150125.png';
import churchImg04 from './img/20201213.png';
import churchImg05 from './img/20210704.png';

export default function IntroducePastor() {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `translateY(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <section className={styles.container}>
      <Background
        title={'교회역사'}
        subtitle={'History'}
        imageUrl={backgroundImg}
      />
      <article className={styles.introduction} style={style}>
        <h2 className={styles.title}>베를린 새누리교회</h2>
        <div className={styles.divider} />
        {introduction.map((text, index) => (
          <p key={index} className={styles.text}>
            {text ? text : <br />}
          </p>
        ))}
      </article>
      <Timeline items={timelineItems} />
    </section>
  );
}

const introduction = [
  '“위로하라, 내 백성을 위로하라” – 이사야, 40장 1절',
  '베를린을 중심으로 한 영혼, 한 영혼에게 위로가 되는 교회를 만들고자 합니다.',
  '',
  '“너희는 세상의 빛과 소금이라” – 마태복음, 5장 13-14절',
  '이웃과 사회에 빛과 소금의 역할을 하는 교회를 만들고자 합니다.',
  '',
  '“보라, 내가 새 일을 행하리니 이제 나타낼 것이다” – 이사야, 43장 19절',
  '지역사회에서 하나님의 십자가를 지고 세상을 섬기고자 합니다.',
];

const timelineItems = [
  {
    date: '2013.05.12',
    textList: ['동베를린에 위치한', '담임목사 사택에서 개척예배 시작'],
    imgUrl: churchImg01,
  },
  {
    date: '2013.10.25',
    textList: ['베를린 Bundesplatz에 위치한', '한 교회에서 창립예배'],
    imgUrl: churchImg02,
  },
  {
    date: '2015.1.25',
    textList: ['베를린 Alexander Platz으로 예배당 이전'],
    imgUrl: churchImg03,
  },
  {
    date: '2020.12.13',
    textList: ['베를린 Pankow에 위치한', 'Hoffnungskirche으로 예배당 이전'],
    imgUrl: churchImg04,
  },
  {
    date: '2021.7.4',
    textList: [
      '베를린 Mitte에 위치한',
      'Theologisches Konvikt으로 예배당 이전',
    ],
    imgUrl: churchImg05,
  },
];
