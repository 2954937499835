import axios from 'axios';
import mockBloggerNewsData from './mockBloggerNewsData.json';

export default class Blogger {
  constructor(channelId) {
    this.httpClient = axios.create({
      baseURL: `https://www.googleapis.com/blogger/v3/blogs/${channelId}/posts`,
      params: { key: process.env.REACT_APP_BLOGGER_API_KEY },
    });
  }

  async get(pageToken = undefined) {
    return this.httpClient
      .get(
        '',
        pageToken && {
          params: {
            pageToken: pageToken,
          },
        }
      )
      .then((res) => {
        return {
          nextPageToken: res.data.nextPageToken,
          items: res.data.items.map((item) => {
            return {
              title: item.title,
              content: item.content,
              date: item.published
                .slice(0, item.published.indexOf('T'))
                .split('-'),
            };
          }),
        };
      })
      .catch(() => {
        console.error('The current post can not be downloaded.');

        return {
          nextPageToken: undefined,
          items: mockBloggerNewsData.map((item) => {
            return {
              title: item.title,
              content: item.content,
              date: item.published
                .slice(0, item.published.indexOf('T'))
                .split('-'),
            };
          }),
        };
      });
  }
}
