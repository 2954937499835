import React, { useEffect, useState } from 'react';
import styles from './IconCard.module.css';

export default function IconCard({ children, title, infos, links }) {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `translateY(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <li className={styles.container} style={style}>
      <div className={styles.icon}>{children}</div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.divider} />
      <div className={styles.infos}>
        {infos.map((info, index) =>
          links ? (
            <a key={index} href={links[index]} target="blank">
              {info}
            </a>
          ) : (
            <p key={index}>{info}</p>
          )
        )}
      </div>
    </li>
  );
}
