import React, { useEffect, useState } from 'react';
import styles from './Timeline.module.css';

export default function Timeline({ items }) {
  const [style, setStyle] = useState({
    backgroundImage: `url(${items[0].imgUrl})`,
  });

  useEffect(() => {
    document
      .getElementById('timeline-item-0')
      .classList.add(styles['timeline-item--active']);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.classList.remove(styles['timeline-item--active']);

          const item = document.getElementsByClassName(
            styles['timeline-item--active']
          )[0];

          item &&
            setStyle({
              backgroundImage: `url(${
                item.getElementsByClassName(styles.timeline__img)[0].src
              })`,
            });
        } else {
          entry.target.classList.add(styles['timeline-item--active']);

          setStyle({
            backgroundImage: `url(${
              entry.target.getElementsByClassName(styles.timeline__img)[0].src
            })`,
          });
        }
      });
    }, options);

    for (let i = 0; i < items.length; i++) {
      const timelineItem = document.getElementById(`timeline-item-${i}`);
      observer.observe(timelineItem);
    }
  }, [items]);

  return (
    <article className={styles['timeline-container']} style={style}>
      <div className={styles['timeline-header']}>
        <h2 className={styles['timeline-header__title']}>
          The History Of Our Church
        </h2>
        <h3 className={styles['timeline-header__subtitle']}>Berlin Saenuree</h3>
      </div>
      <ul className={styles.timeline}>
        {items.map(({ date, textList, imgUrl }, index) => (
          <li
            key={index}
            id={`timeline-item-${index}`}
            className={styles['timeline-item']}
            data-text={'Berlin Saenuree'}
          >
            <div className={styles.timeline__content}>
              <img
                className={styles.timeline__img}
                src={imgUrl}
                alt={`img-${index}`}
              />
              <h2 className={styles['timeline__content-title']}>{date}</h2>
              <div className={styles['timeline__content-desc']}>
                {textList.map((text, index) => (
                  <p key={index}>{text}</p>
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </article>
  );
}
