import React, { useState, useRef } from 'react';
import styles from './YoutubeVideo.module.css';
import { HiOutlineVideoCamera } from 'react-icons/hi';

export default function Video({ title, frameData }) {
  const [clicked, setClicked] = useState(false);
  const videoRef = useRef();
  const infos = frameData.snippet.title.split('|');
  const data = {
    title: infos[0].substring(infos[0].indexOf(']') + 1),
    group: infos[1],
  };

  const handlePlayVideo = () => {
    setClicked(true);

    videoRef.current.setAttribute(
      'src',
      videoRef.current.getAttribute('src') + '?autoplay=1'
    );
  };

  return (
    <article className={styles.container}>
      <iframe
        ref={videoRef}
        className={`${styles.iframe} ${clicked && styles.show}`}
        title={title}
        src={`https://www.youtube.com/embed/${frameData.id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

      {clicked ? (
        <></>
      ) : (
        <div className={styles['thumbnails-wrapper']}>
          <img
            className={styles.thumbnails}
            src={frameData.snippet.thumbnails.high.url}
            alt="thumbnails"
          />
          <div
            className={styles['thumbnails-filter']}
            onClick={handlePlayVideo}
          >
            <div className={styles['icon-background']}>
              <HiOutlineVideoCamera className={styles.icon} />
            </div>
          </div>
        </div>
      )}

      <p className={styles.date}>{data.group}</p>
      <p className={styles.title}>{data.title}</p>
    </article>
  );
}
