import React from 'react';
import styles from './DarkModeButton.module.css';
import { BiSun, BiMoon } from 'react-icons/bi';
import { useDarkMode } from '../../context/DarkModeContext';

export default function DarkModeButton() {
  const { darkMode, toggleDarkMode } = useDarkMode();

  return (
    <div className={styles.background}>
      <p
        className={`${styles['button-wrapper']} ${darkMode && styles.selected}`}
      >
        <button className={styles.button} onClick={toggleDarkMode}>
          {darkMode ? (
            <BiMoon className={styles.icon} />
          ) : (
            <BiSun className={styles.icon} />
          )}
        </button>
      </p>
    </div>
  );
}
