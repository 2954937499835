import React, { useEffect, useState } from 'react';
import styles from './MainScreen.module.css';

export default function MainScreen() {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `scale(1.5)`,
  });
  const sunday = getLastSunday();

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 600);
  }, []);

  return (
    <>
      <div className={styles.background}>
        <div className={styles.background__filter}>
          <h1 className={styles.title} style={style}>
            베를린 새누리교회
          </h1>
          <h2
            className={styles.subtitle}
            style={style}
          >{`${sunday.month}월 ${sunday.day}일 주일예배를 준비하는 공동체`}</h2>
        </div>
      </div>
    </>
  );
}

const getLastSunday = () => {
  const sunday = new Date();
  sunday.setDate(sunday.getDate() + ((6 - sunday.getDay()) % 7) + 1);

  return { month: sunday.getUTCMonth() + 1, day: sunday.getUTCDate() };
};
