import React, { useEffect, useState } from 'react';
import styles from './Vision.module.css';
import Background from '../../components/Background/Background';
import backgroundImg from './img/background.png';

export default function Vision() {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `translateY(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <section className={styles.container}>
      <Background
        title={'교회 비전'}
        subtitle={'Our Vision'}
        imageUrl={backgroundImg}
      />
      <article className={styles.introduction} style={style}>
        <h2 className={styles.title}>
          <span className={styles['title-emphasize']}>
            "예배는 선교의 동력이자 선교의 목표"
          </span>
          를 중심으로
        </h2>

        <div className={styles.divider} />

        <ul>
          <li className={styles['text-container']}>
            <h3>
              하나님께서 기뻐하시는{' '}
              <span className={styles['subtitle-emphasize']}>
                참된 예배를 회복
              </span>
              시키는 것
            </h3>
            <p>
              “형제들아 내가 하나님의 모든 자비하심으로 너희를 권하노니 너희
              몸을 하나님이 기뻐하시는 거룩한 산 제물로 드리라 이는 너희가 드릴
              영적 예배니라”
            </p>
            <p className={styles.verse}>로마서 12장 1절</p>
          </li>

          <li className={styles['text-container']}>
            <h3>
              하나님께서 기뻐하시는{' '}
              <span className={styles['subtitle-emphasize']}>
                올바른 기독교 문화를 회복
              </span>
              시키는 것
            </h3>
            <p>
              “너희는 이 세대를 본받지 말고 오직 마음을 새롭게 함으로 변화를
              받아 하나님의 선하시고 기뻐하시고 온전하신 뜻이 무엇인지
              분별하도록 하라”
            </p>
            <p className={styles.verse}>로마서 12장 2절</p>
          </li>

          <li className={styles['text-container']}>
            <h3>
              <span className={styles['subtitle-emphasize']}>
                영혼을 치유하며 회복
              </span>
              시키는 것
            </h3>
            <p>“너희의 하나님이 이르시되 너희는 위로하라 내 백성을 위로하라”</p>
            <p className={styles.verse}>이사야 40장 1절</p>
          </li>

          <li className={styles['text-container']}>
            <h3>
              새벽이슬 같은{' '}
              <span className={styles['subtitle-emphasize']}>주의 청년</span>
              들을 하나님 나라를 위한{' '}
              <span className={styles['subtitle-emphasize']}>신실한 일꾼</span>
              들로 세우는 것
            </h3>
            <p>
              “주의 권능의 날에 주의 백성이 거룩한 옷을 입고 즐거이 헌신하니
              새벽 이슬같은 주의 청년들이 주께 나오는도다”
            </p>
            <p className={styles.verse}>시편 130편 3절</p>
          </li>
        </ul>
      </article>
    </section>
  );
}
