import React from 'react';
import styles from './Home.module.css';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import MainScreen from '../../components/MainScreen/MainScreen';
import { useQuery } from '@tanstack/react-query';
import { useYoutubeApi } from '../../context/YoutubeApiContext';

export default function Home() {
  const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
  const { youtube } = useYoutubeApi();
  const { data: videos } = useQuery(
    ['videos', channelId],
    async () => youtube.searchByChannelId(channelId),
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  return (
    <div className={styles.container}>
      <MainScreen />
      <div className={styles.padding} />
      <section className={styles['title-container']}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.divider}></div>
        {verse.split('|').map((text, index) => (
          <p className={styles.verse} key={index}>
            {text}
          </p>
        ))}
      </section>

      <section className={styles['video-container']}>
        {videos && (
          <ul className={styles.videos}>
            {videos.map((frameData, index) => (
              <li key={index}>
                <YoutubeVideo frameData={frameData} />
              </li>
            ))}
          </ul>
        )}

        <div className={styles['youtube-container']}>
          <button className={styles['youtube-button']}>
            <a
              className={styles['youtube-link']}
              href="https://www.youtube.com/@berlinsaenuree/videos"
              target="blank"
            >
              더 많은 영상 보기
            </a>
          </button>
        </div>
      </section>
    </div>
  );
}

const title = '매일같이 기대되는 하루';
const verse =
  '일어나라 빛을 발하라 이는 네 빛이 이르렀고 여호와의 영광이 네 위에 임하였음이니라 (사 60:1)';
