import { DarkModeProvider } from './context/DarkModeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

const queryClient = new QueryClient();

export default function App() {
  return (
    <DarkModeProvider>
      <Navbar menus={menus} />
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
      <Footer />
    </DarkModeProvider>
  );
}

const menus = [
  {
    title: '교회 소개',
    link: '/introduce/greetings',
    menus: [
      { title: '교회역사', link: '/introduce/greetings' },
      { title: '담임목사 소개', link: '/introduce/pastor' },
    ],
  },
  {
    title: '우리의 소명',
    link: '/calling/purpose',
    menus: [
      { title: '목회 철학', link: '/calling/purpose' },
      { title: '교회 비전', link: '/calling/vision' },
    ],
  },
  { title: '새누리 소식', link: '/news' },
  { title: '예배 안내', link: '/worship' },
  { title: '위치 안내', link: '/location' },
];
