import React, { useCallback, useState } from 'react';
import styles from './ImageSlider.module.css';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export default function ImageSlider({ imgUrlList }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClickLeftButton = useCallback(() => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  }, []);
  const handleClickRightButton = useCallback(() => {
    setSelectedIndex((prevIndex) =>
      prevIndex < imgUrlList.length - 1 ? prevIndex + 1 : prevIndex
    );
  }, [imgUrlList]);
  const updateStyle = useCallback(
    (index) => {
      if (index === selectedIndex) {
        return { zIndex: '1' };
      }
      if (index === selectedIndex - 1) {
        return {
          transform: `scale(0.9) translateX(-30%)`,
          filter: `blur(3px)`,
        };
      }
      if (index === selectedIndex + 1) {
        return {
          transform: `scale(0.9) translateX(30%)`,
          filter: `blur(3px)`,
        };
      }
      return { display: 'none' };
    },
    [selectedIndex]
  );

  return (
    <div className={styles.container}>
      <button className={styles['button-left']} onClick={handleClickLeftButton}>
        <FiChevronLeft className={styles.icon} />
      </button>
      <button
        className={styles['button-right']}
        onClick={handleClickRightButton}
      >
        <FiChevronRight className={styles.icon} />
      </button>
      <ul className={styles['slider-container']}>
        {imgUrlList.map((imgUrl, index) => (
          <li key={index} className={styles.item} style={updateStyle(index)}>
            <img
              className={styles.img}
              src={imgUrl}
              alt={`churchImg${index}`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
