import mockYoutubeData from './mockYoutubeData.json';

export default class Youtube {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async searchByChannelId(channelId, count = 2) {
    return this.apiClient
      .search({
        params: {
          part: 'snippet',
          maxResults: count,
          type: 'video',
          order: 'date',
          channelId,
        },
      })
      .then((res) =>
        res.data.items.map((item) => ({ ...item, id: item.id.videoId }))
      )
      .catch(() =>
        mockYoutubeData.items.map((item) => ({ ...item, id: item.id.videoId }))
      );
  }
}
