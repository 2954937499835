import React, { useEffect, useState } from 'react';
import styles from './Purpose.module.css';
import Background from '../../components/Background/Background';
import crossImgUrl from './img/cross.png';
import backgroundImg from './img/background.png';

export default function Purpose() {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `translateY(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <section>
      <Background
        title={'목회 철학'}
        subtitle={'Pastoral Purpose'}
        imageUrl={backgroundImg}
      />

      <article className={styles.introduction} style={style}>
        <div className={styles['img-wrapper']}>
          <img className={styles.img} src={crossImgUrl} alt="cross" />
        </div>
        <ul>
          <li className={styles['text-container']}>
            <h2>
              날마다 개척하는{' '}
              <span className={styles['text-emphasize']}>마음</span>
              으로 교회를 섬김니다
            </h2>
          </li>

          <li className={styles['text-container']}>
            <h2>
              매 예배의 설교를{' '}
              <span className={styles['text-emphasize']}>마지막</span>
              처럼 여기고 <span className={styles['text-emphasize']}>복음</span>
              을 전합니다
            </h2>
          </li>

          <li className={styles['text-container']}>
            <h2>
              만나게 하신{' '}
              <span className={styles['text-emphasize']}>한 영혼, 한 영혼</span>
              마다 최선을 다해{' '}
              <span className={styles['text-emphasize']}>사랑</span>
              합니다
            </h2>
          </li>
        </ul>
      </article>
    </section>
  );
}
