import React, { useState, useEffect } from 'react';
import styles from './MenuButton.module.css';
import { Link } from 'react-router-dom';

export default function MenuButton({
  menu,
  selectedMenuTitle,
  onClick,
  openMenuBar,
}) {
  const { title, menus: submenus, link } = menu;
  const [isHover, setIsHover] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const sizeMedium = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--size-lg')
  );

  const handleMouseMove = (isEnter) => {
    if (window.innerWidth < sizeMedium) return;
    setIsHover(isEnter);
  };
  const handleMenuClick = (e) => {
    if (window.innerWidth >= sizeMedium) {
      onClick();
      return;
    }

    setIsClicked((prev) => !prev);
    submenus && e.preventDefault();
    submenus || onClick();
  };
  const handleSubmenuClick = () => {
    setIsHover(false);
    onClick();
  };

  useEffect(() => {
    setIsClicked(false);
  }, [openMenuBar]);

  return (
    <li className={styles['main-container']}>
      <Link
        to={link}
        className={`${styles.title} ${
          title === selectedMenuTitle && styles.selected
        }`}
        onMouseEnter={() => handleMouseMove(true)}
        onMouseLeave={() => handleMouseMove(false)}
        onClick={handleMenuClick}
      >
        {title}
      </Link>

      {submenus && (
        <div
          className={`${styles['submenu-container']} ${
            (isHover || isClicked) && styles.show
          }`}
          onMouseEnter={() => handleMouseMove(true)}
          onMouseLeave={() => handleMouseMove(false)}
        >
          <div className={styles.point} />
          <ul className={styles.submenus}>
            {submenus.map(({ title, link }, index) => (
              <li className={styles['submenu-wrapper']} key={index}>
                <Link
                  to={link}
                  className={styles.submenu}
                  onClick={handleSubmenuClick}
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}
