import React, { useState } from 'react';
import styles from './Map.module.css';
import imgSmall from './img/small.png';
import imgNormal from './img/normal.png';
import imgMedium from './img/medium.png';
import imgLarge from './img/large.png';

const imgArray = [imgSmall, imgNormal, imgMedium, imgLarge];

export default function Map() {
  const [imgIndex, setImgIndex] = useState(0);
  const [toShowFilter, setToShowFilter] = useState(false);

  const handleWheel = (e) => {
    toShowFilter || setToShowFilter(true);
    setTimeout(() => setToShowFilter(false), 1000);
  };
  const handleZoomIn = () => {
    setImgIndex((prevIndex) =>
      prevIndex < imgArray.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleZoomOut = () => {
    setImgIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  return (
    <article className={styles.container}>
      <img className={styles.image} src={imgArray[imgIndex]} alt="map" />

      <p
        className={`${styles['image-filter']} ${toShowFilter && styles.show}`}
        onWheel={handleWheel}
      >
        '확대/축소를 하려면 오른쪽 버튼을 사용하세요.'
      </p>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={handleZoomIn}>
          +
        </button>
        <div className={styles.divider} />
        <button className={styles.button} onClick={handleZoomOut}>
          -
        </button>
      </div>
    </article>
  );
}
