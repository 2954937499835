import React, { useCallback } from 'react';
import styles from './ImagePost.module.css';
import { useNavigate } from 'react-router-dom';

export default function ImagePost({ post: { title, date, content }, index }) {
  const navigate = useNavigate();
  const handleClickItem = useCallback(
    (e) => {
      navigate(`/news/${index}`);
    },
    [index, navigate]
  );
  const getFirstImgTag = useCallback((htmlString) => {
    const rootElement = document.createElement('div');
    rootElement.style.display = 'none';
    document.body.appendChild(rootElement);
    rootElement.innerHTML = htmlString;

    const imgTag = rootElement.querySelector('img');
    rootElement.remove();

    return imgTag;
  }, []);

  const imgTag = getFirstImgTag(content);

  return (
    <div className={styles['container']} onClick={handleClickItem}>
      {imgTag && (
        <img
          className={styles['preview-image']}
          src={imgTag.src}
          alt={`news${index}`}
        />
      )}
      <div className={styles['text-container']}>
        <h1 className={styles.title}>{title}</h1>
        <h3
          className={styles.date}
        >{`${date[0]}년 ${date[1]}월 ${date[2]}일 `}</h3>
      </div>
    </div>
  );
}
