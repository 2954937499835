import React from 'react';
import styles from './Background.module.css';
import { useQuery } from '@tanstack/react-query';

export default function Background({ title, subtitle, imageUrl }) {
  const fileName = imageUrl.split('/').pop();
  const { isLoading, data: url } = useQuery(
    ['background', fileName],
    async () => fetch(imageUrl).then((res) => res.url),
    { staleTime: 1000 * 60 * 10 }
  );

  return (
    <section
      className={styles.container}
      style={
        isLoading
          ? {}
          : {
              backgroundImage: `url(${url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }
      }
    >
      <div className={styles.filter}>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && (
          <>
            <br />
            <h2 className={styles.subtitle}>{subtitle}</h2>{' '}
          </>
        )}
      </div>
    </section>
  );
}
