import React, { useCallback, useState } from 'react';
import styles from './News.module.css';
import Blogger from '../../api/blogger';
import { useQuery } from '@tanstack/react-query';
import Background from '../../components/Background/Background';
import ImagePost from '../../components/ImagePost/ImagePost';

export default function News() {
  const [, updateState] = useState();
  const { data: postData } = useQuery(
    ['news', process.env.REACT_APP_BLOGGER_NEWS_CHANNEL_ID],
    () => new Blogger(process.env.REACT_APP_BLOGGER_NEWS_CHANNEL_ID).get(),
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  const handleClickPostButton = useCallback(() => {
    new Blogger(process.env.REACT_APP_BLOGGER_NEWS_CHANNEL_ID)
      .get(postData.nextPageToken)
      .then(({ nextPageToken, items }) => {
        postData.nextPageToken = nextPageToken;
        postData.items.push(...items);

        updateState({});
      })
      .catch(() => console.error('The current post can not be downloaded.'));
  }, [postData]);

  return (
    <section>
      <Background
        title={'새누리 소식'}
        subtitle={'Good News'}
        imageUrl={'/img/sharing.png'}
      />
      <ul className={styles['post-container']}>
        {postData &&
          postData.items.map((post, index) => (
            <li key={index}>
              <ImagePost post={post} index={index} />
            </li>
          ))}
      </ul>
      {postData && postData.nextPageToken && (
        <div className={styles['post-button-container']}>
          <button
            onClick={handleClickPostButton}
            className={styles['post-button']}
          >
            더 많은 소식 보기
          </button>
        </div>
      )}
    </section>
  );
}
