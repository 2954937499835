import React from 'react';
import styles from './Worship.module.css';
import Background from '../../components/Background/Background';
import InformationCard from '../../components/InformationCard/InformationCard';
import backgroundImg from './img/background.png';

export default function Worship() {
  return (
    <div>
      <Background
        title={'예배 안내'}
        subtitle={'Here I am to Worship'}
        imageUrl={backgroundImg}
      />
      <section className={styles.container}>
        <div className={styles['title-container']}>
          <div className={styles['divider-left']} />
          <h2 className={styles.title}>예배시간 및 장소 안내</h2>
          <div className={styles['divider-right']} />
        </div>
        <ul className={styles.worships}>
          {worships.map((worship, index) => (
            <li key={index}>
              <InformationCard
                title={worship.title}
                time={worship.time}
                timeDetail={worship.timeDetail}
                place={worship.place}
              />
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

const worships = [
  { title: '주일 예배', time: '주일 11:00', place: 'GROSSER SAAL' },
  { title: '주일 학교', time: '주일 11:30', place: 'GROSSER SAAL' },
  {
    title: '새벽 예배',
    time: '아침 7:00',
    timeDetail: '(월 ~ 토)',
    place: 'GROSSER SAAL',
  },
];
