import React, { useEffect, useState } from 'react';
import styles from './Pastor.module.css';
import avatar from './img/avatar.jpg';
import backgroundImg from './img/background.png';
import Background from '../../../components/Background/Background';

export default function IntroducePastor() {
  const [styleLeft, setStyleLeft] = useState({
    opacity: 0,
    transform: `translateX(-100px)`,
  });
  const [styleRight, setStyleRight] = useState({
    opacity: 0,
    transform: `translateX(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyleLeft({ opacity: 1 });
      setStyleRight({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <section className={styles.container}>
      <Background
        title={'담임목사'}
        subtitle={'Pastor'}
        imageUrl={backgroundImg}
      />
      <article className={styles['introduction-container']}>
        <div className={styles['avatar-wrapper']}>
          <img
            className={styles.avatar}
            style={styleLeft}
            src={avatar}
            alt="avatar"
          />
        </div>
        <div className={styles.introduction} style={styleRight}>
          <h2 className={styles.title}>이한주 담임목사</h2>
          <h3 className={styles.subtitle}>Pastor, Lee Hanju</h3>
          {introduction.map((text, index) => (
            <p key={index} className={styles.text}>
              {text}
            </p>
          ))}
        </div>
      </article>
      <article className={styles.history}>
        <h3 className={styles.subtitle}>소명 간증서</h3>
        <ul>
          {testimony.map(({ title, text }, index) => (
            <li className={styles.text} key={index}>
              <h4>{title}</h4>
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </article>
    </section>
  );
}

const introduction = [
  '(대전 침례신학 대학원 M.Div.)',
  '',
  '',
  '이한주 담임목사는 하나님의 사랑을 삶으로 실천하는 목회자입니다.',
  '오직 예수 그리스도를 섬기는 마음으로 삶을 통해 목회를 하고 있습니다.',
  '',
  '하나님 앞에 바로 서기 위해 매일 새벽을 기도로 깨우는 일꾼,',
  '예수님을 향한 순수한 사랑으로 가득 찬 사역자,',
  '오직 영혼을 위하는 마음으로 꽉찬 목회자입니다.',
];
const testimony = [
  {
    title: '',
    text: '2000년 1월 7일에 독일로 유학을 떠났습니다. 흔히 사람들은 저마다 야심찬 인생의 목적을 가지고 유학의 길을 떠납니다. 하지만 간증문을 작성하는 현재 그 시절을 돌이켜 보니 하나님께서는 본인의 목적과는 다른 특별한 목적이 있으셔서 독일 땅으로 인도하셨다는 사실을 깨닫게 됩니다. 지금까지 제 인생 가운데 역사하시고 인도하신에벤에셀의 하나님을 소개하겠습니다. 또한 앞으로 인도하실 여호와 이레의 하나님을 기대하며 주신 소명과 사명을 밝히고자 합니다.',
  },
  {
    title: '예술가로서의 꿈을 꾸던 시절',
    text: '스므 살 즈음에 처음으로 무엇인가 좋아하는 일이 생겼는데 그것이 사진이었습니다. 30대 초반 까지만 해도 사진을 통해 유명한 사진작가가 되겠다는 것이 유일한 꿈이었습니다. 사진 작업은 제안에 숨은 감정을 토해낼 수 있는 유일한 통로가 되었습니다. 그래서 제가 작업한 사진 속에는 20대에 겪은 방황과 고독, 30대의 신앙 안에서 겪게되는 갈등이 고스란히 담겨져 있습니다. 한 때는 저도 남들처럼 사진을 통해 세계적인 예술가로 인정받는 것을 꿈꾸기도 했습니다. 그 당시에는 나를 향하신 하나님의 꿈이있다는 것을 알지 못했습니다. 그렇게 시간은 흘러서 제 나이 40에 들어서면서 내 인생을 향하신 하나님의 다른 꿈이 있었다는 것을 깨닫게 되었습니다.',
  },
  {
    title: '',
    text: '지금에 와서 돌이켜 보면 그동안 하나님의 부르심의 싸인은 여러 번 있었습니다. 저는 전공인 사진과를 두 번이나 중퇴했던 쑥스러운 과거가 있습니다. 한번은 한국에서 학업을 중단했고 또 한 번은 독일에서 학업을 중단했습니다. 그런 화려한 전력이 있었기에 신학공부를 위해 한국으로 떠나야 하는 저를 공항에 배웅 나 온 아내의 마지막 외침은 이것 이었습니다. ‘여보 이번에는 꼭 졸업하고 돌아와. 화이팅’',
  },
  {
    title: '결혼과 가정을 통한 치유와 회복',
    text: '2000년, 늦은 가을에 독일에서 아내를 만났습니다. 그 당시 아내는 일찍이 독일로 유학 와서 박사과정을 마무리할 무렵이었고 저희들은 만난 지 2개월 만에 한국 사람으로는 최초로 독일 라이프찌히 교회에서 결혼했습니다. 당시 아내는 주님을 향한 믿음과 사랑이 신실한 자매였습니다. 반면에 본인은 세상 속에서 많이 방황하고 있었습니다. 그러한 나에게 아내는 하나님의 사랑이 무엇인지를 헌신적 삶을 통해 보여 주었고 본인은 가정을 통해서 내면의 깊은 상처들이 치유를 받기 시작했습니다. 아내는 결혼전부터 현재까지 제게 원하는 유일한 한 가지 요구 사항이 있습니다.',
  },
  {
    title: '',
    text: '그 한 가지 요구는 성령 충만한 사람이 되는 것이었습니다. 저는 아내의 그 요구가 진심이라는 것을 알았기에 13년 전부터 시작한 성령 충만을 구하는 새벽 기도는 지금까지 이어지고 있습니다.',
  },
  {
    title: '',
    text: '그리고2008년 1월에 Berlin으로 이사를 했습니다. 아내가 베를린 국립 오페라 극장에서 일을 시작했기 때문입니다. 한동안은 하나님께서 저희 가정을 베를린으로 인도하신 이유를 사진가로 성악가로서 지평을 열어 주시고자하는 뜻으로 오해했습니다. 하지만 하나님께서는 성경을 통해서 당신의 종으로 부르셨음을 분명하게 말씀하셨습니다.',
  },
  {
    title: '한국으로 재 유학과 새누리 2교회',
    text: '2009년 독일에 아내와 두 명의 어린 자녀들을 뒤로하고 한국으로 재 유학의 길에 올랐습니다. 수많은 신학교들 중에서 대전 침례 신학대학교로 인도하셔서 M.div과정에공부하게 되었습니다. 신학교에서 공부하는 지난 3년의 시간은 참으로 감사하고 행복했습니다. 그 중에서 특별히 감사한 것은 새 누리 2교회와의 만남입니다. 새누리 2교회의 안진섭 목사님을 통해서 성경을 바르게 바라보는 방법을 배웠습니다. 강단에서 설교자 자신의 생각을 최대한 배제하고 성경이 무엇을 말하고 있는지 찾으려 노력하시는목사님의 모습은 본인의 설교방향에 큰 영향을 미쳤습니다. 또한 목장 식구들 한 분 한 분은 가족을 독일에 두고 온 저에게는 영적인 가족이나 다름없었습니다. 매주 있는목장 모임을 통해서는 겸손한 목자의 섬김의 본과 교회 공동체가 무엇이지를 배우는 시간이었습니다.',
  },
  {
    title: '소명',
    text: '성령님께서는 날마다 새벽예배를 통해서 나를 만나주셨습니다. 그리고 내면 깊은 곳에 숨어있던 죄의 문제를 용서하시고 마음의 상처를 치유하시기 시작했습니다. 제가이때 경험한 하나님은 위로하시는 하나님이었습니다. 새벽예배 때 마다 하나님 앞에 나의 모든 것을 내려놨습니다. 나의 억울함, 외로움, 두려움, 염려, 그리고 내면 깊은 곳에 숨겨진 상처와 죄의 문제까지 십자가 앞에 내려놨습니다. 하나님께서 저를 독일이라는 광야로 인도하셨고 성령께서 첫 번째로 하신 사역은 지독한 내면의 상처를 치유하시고 위로하시는 일 이었습니다. 성령의 치유와 위로하시는 사역은 현재 본인의 삶속에서 지속적으로 이루어지고 있는데 이 일을 통해서 소명과 사명을 발견하게 됩니다.',
  },
  {
    title: '',
    text: '하나님께서는 이사야서 40장1절,  ‘위로하라 내 백성을 위로하라’는 말씀으로 첫 번째 소명을 주셨습니다. 요즘 세대, 한국 땅이나 독일 땅이나 할 것 없이 성령님의 위로를 필요로 하는 사람이들 너무도 많이 있습니다. 구원받은 하나님의 자녀들이라고 할지라도 수많은 내면의 상처가 있습니다. 다른 사람들에게는 말 못할 아픔을 겪고 살아가는 분들이 너무나도 많이 있는 것을 보게 됩니다.',
  },
  {
    title: '',
    text: '두 번째 소명의 말씀으로 마태복음24장45절을 주셨습니다. ‘충성되고 지혜 있는 종이 되어 주인에게 그 집 사람들을 맡아 때를 따라 양식을 나눠 줄 자가 누구냐’는 말씀을 통해 설교자로의 소명을 갖게 되었습니다.',
  },
  {
    title: '',
    text: '세 번째 소명의 말씀으로 요한복음20장21절을 주셨습니다. ‘예수께서 이르시되 너희에게 평강이 있을지어다 아버지께서 나를 보내신 것같이 나도 너희를 보내노라’본인은 이 말씀을 통해서 샬롬을 이루어가는 공동체를 꿈꾸게 되었습니다.',
  },
];
